

import { Box, Grow, Typography } from '@mui/material'
import { Service } from '../../../data/services'
import { useState } from 'react';
import './SubCats.css'

interface Props {
    service: Service; 
}

export const SubCategoriesSection = ({service}: Props) => {

    const [showSubs, setShowSubs] = useState<number[]>([])

    const includesInShow = (index: number) => {
        return showSubs.filter(x => x === index).length > 0
    }

  return (
    <div className='subcats-container'>
        <div className='subcats-content' >
            {
                service.subcategories.map((x, index) => (
                    <>
                        <div style={{ maxWidth: '1200px', width:'100%' }} onClick={() => {
                            if(includesInShow(index)){
                                setShowSubs(showSubs.filter(z => z !== index))
                            } else {
                                setShowSubs([...showSubs, index])
                            }
                        }}>
                            <Box
                                marginBottom={'20px'}
                                borderBottom={`2px solid ${includesInShow(index) ? '#FCBC45' : 'black'}`}
                                width={'100%'}
                                color={includesInShow(index) ? '#FCBC45' : 'black'}
                                sx={{ 
                                    cursor:'pointer',
                                    '&:hover': {
                                        borderBottomColor: '#FCBC45'
                                    }
                                }}
                            >
                                <Typography 
                                    textAlign={'center'} 
                                    marginBottom={'10px'} 
                                    key={index}
                                    sx={{
                                        '&:hover': {
                                            color: '#FCBC45'
                                        }
                                    }}
                                >
                                    {x.name}
                                </Typography> 
                            </Box>
                        </div>
                        {
                            includesInShow(index) && (
                                <Grow in={includesInShow(index)} style={{ transformOrigin: '0 0 0' }}
                                {...(includesInShow(index) ? { timeout: 1000 } : {})}>
                                    <Box marginBottom={'30px'}>
                                        {
                                            x.detail.map((x, index) => (
                                                <Typography 
                                                    key={index}
                                                    paddingLeft={x.includes('•') ? '40px' : undefined} 
                                                    marginBottom={'20px'}
                                                >
                                                    {x}
                                                </Typography>
                                            ))
                                        }
                                    </Box>
                                </Grow>
                            )
                        }
                    </>
                ))
            }
        </div>
        {
            service.name === 'Legales'
                ? <img 
                    width={'40%'} 
                    style={{ borderRadius: '20px', marginTop: '60px' }} 
                    height={'auto'} 
                    src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1696180182/romain-dancre-doplSDELX7E-unsplash_1_i7mls7.jpg' 
                    alt='imagen-legales' 
                />
                : service.name === 'Contabilidad'
                    ? <img 
                        width={'40%'} 
                        style={{ borderRadius: '20px', marginTop: '60px' }} 
                        height={'auto'} 
                        src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1696181337/scott-graham-5fNmWej4tAA-unsplash_1_1_rthqm4.jpg' 
                        alt='imagen-contabilidad' 
                    />
                    : <img 
                        width={'40%'} 
                        style={{ borderRadius: '20px', marginTop: '60px' }} 
                        height={'auto'} 
                        src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1696181651/kelly-sikkema-8DEDp6S93Po-unsplash_2_b9ndma.jpg' 
                        alt='imagen-contabilidad' 
                    />
        }
    </div>
  )
}
