
import './ScrollTop.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export const WhatsAppButton = () => {

  const onTop = () => {
    window.open('https://wa.me/+5491157690916')
  }

  return (
    <button
      className={"whats-button"}
      onClick={onTop}
    >
      <WhatsAppIcon sx={{ color: 'white' }} />
    </button>
  )
}
