
import './Home.css'
import logo from './logroblanco.png'

interface Props {
  goQuienes: () => void
}

export const Home = ({goQuienes}: Props) => {
  return (
    <div className="container">
      <div className="background"></div>
      <div className="overlay"></div>
        <div className="content">
            <img alt='logo-mr-asoc-blanco' src={logo} width={400} height={'auto'} />
            <div className='home-content'>
              <h2>Matias Romero & Asociados.</h2>
              <p>Somos un estudio contable joven formado por profesionales graduados en las universidades de Buenos Aires. Acompañamos y asesoramos a todo tipo de empresas, dándoles un servicio integral de asesoramiento fiscal, contable, laboral y financiero. </p>
              <button onClick={goQuienes} className='be-expositor'>
                MÁS INFORMACIÓN
              </button>
            </div>
            
        </div>
    </div> 
  )
}
