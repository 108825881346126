

import { Link } from 'react-router-dom';
import './Services.css'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface IService {
    img: string;
    title: string;
    subtitule: string;
    readmore: string | undefined;
}

const data: IService[] = [
    {img: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1696186677/payroll_rwextg.png',
    title: 'Asesoría Laboral',
    subtitule: 'Nuestro equipo de asesores laborales te asesorará y gestionará todos los trámites vinculados con las relaciones laborales de tu empresa de forma directa y personalizada para que no tengas que preocuparte de nada.',
    readmore: undefined},
    {img: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1696257585/outsourcing_ur7em8.png',
    title: 'Asesoría para Particulares',
    subtitule: 'Gestionamos y te asesoramos acerca de los asuntos que te afectan a nivel personal y familiar. No sólo las empresas necesitan un asesoramiento fiscal especializado, sino que las personas a nivel particular también pueden obtener grandes beneficios de él.',
    readmore: undefined},
    {img: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1696186677/company_dwx8se.png',
    title: 'Asesoría para Pymes, Autónomos y Empresas',
    subtitule: 'Todos nuestros servicios de gestoría para autónomos, pymes y empresas incluyen un asesoramiento de calidad y personalizado. Nuestros servicios de gestoría están adaptados a las necesidades concretas de nuestros autónomos y emprendedores.',
    readmore: undefined},
    {img: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1696186677/business_k2lfot.png',
    title: 'Contabilidad',
    subtitule: 'Registro meticuloso, análisis profundo. Información clara para decisiones sólidas. Control financiero experto, crecimiento empresarial respaldado por datos confiables.',
    readmore: '/servicios/contabilidad'},
    {img: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1696186676/bookkeeping_sk8u4c.png',
    title: 'Legales',
    subtitule: 'Seguridad jurídica en cada paso. Asesoría completa en aspectos legales. Resguardo de tu empresa y resolución de desafíos legales.',
    readmore: '/servicios/legales'},
    {img: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1696186677/personal_dc5ue1.png',
    title: 'Impuestos',
    subtitule: 'Estrategias fiscales precisas para maximizar ganancias. Cumplimiento normativo y reducción de cargas tributarias, asegurando tranquilidad financiera.',
    readmore: '/servicios/impuestos'}
]


export const Services = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="services-content">
            <h3>Nuestros Servicios</h3>
            <div className='service-row'>
                <h2>¿Qué servicios ofrecemos?</h2>
                <p>
                    Soluciones Contables y Estratégicas: Potenciamos tu negocio con asesoramiento integral y adaptado a tus necesidades empresariales.
                </p>
                <Link to={'/servicios'}>
                    <button className='be-expositor'>
                        TODOS LOS SERVICIOS
                    </button>
                </Link>
            </div>

            <div 
                style={{ 
                    display: 'grid', 
                    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
                    gap: '30px',
                    marginTop: '30px'
                }}
            >
                {
                    data.map((x,index) => (
                        <div key={index} className='service-card'>
                            <img height={45} alt={x.title} width={45} src={x.img} />
                            <div>
                                <h4>{x.title}</h4>
                                <p>{x.subtitule}</p>
                                {
                                    x.readmore && (
                                        <Link to={x.readmore}>
                                            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                <span>LEER MÁS</span>
                                                <ArrowForwardIcon style={{ marginTop: '1px', color: '#FCBC45', marginLeft: '5px' }} />
                                            </div>
                                        </Link>
                                    )
                                }
                            </div>
                        </div>
                    ))
                }
            </div>

        </div>
    </div>
  )
}
