
import './ScrollTop.css'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const LinkedinButton = () => {

  const onTop = () => {
    window.open('https://www.linkedin.com/in/matiasromero98/')
  }

  return (
    <button
      className={"link-button"}
      onClick={onTop}
    >
      <LinkedInIcon sx={{ color: 'white' }} />
    </button>
  )
}
