
import { Box, IconButton, Typography } from '@mui/material'
import './TabsServices.css'
import { SERVICIOS } from '../../../data/services'
import { useEffect, useState } from 'react';
import { SubCategoriesSection } from './SubCategoriesSection';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const TabsServices = () => {

    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const [selectedTab, setSelectedTab] = useState<number | null>(null);
    const [isSwipper, setIsSwipper] = useState(window.innerWidth < 750);

    const handleMouseEnter = (index: number) => {
        setHoveredIndex(index);
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    useEffect(() => {
        const url = window.location.href;
        if(url.includes('contabilidad')){
            setSelectedTab(0)
        } else if(url.includes('legales')){
            setSelectedTab(1)
        } else if (url.includes('impuestos')){
            setSelectedTab(2)
        }

        // ANCHO PANTALLA
        const manejarCambioAncho = () => {
            if(window.innerWidth < 750)
                setIsSwipper(true)
            else
                setIsSwipper(false)
        };
        window.addEventListener('resize', manejarCambioAncho);

        return () => {
            window.removeEventListener('resize', manejarCambioAncho);
        };
    }, [])

    const handleOnPress = (index: number, url: string) => {
        setSelectedTab(index);
    }
    

  return (
    <Box 
        display={'flex'} 
        flexDirection={'column'} 
        alignItems={'center'} 
        marginTop={'40px'}
        minHeight={'70vh'}
        paddingX={'40px'}
        paddingBottom={'80px'}
    >
        <Box 
            display={'flex'} 
            justifyContent={'space-between'} 
            marginBottom={'20px'} 
            maxWidth={'1200px'} 
            width={'100%'}
        >
            {
                isSwipper ? (
                    <Box width={'100%'}>
                        <div 
                            onMouseEnter={() => handleMouseEnter(selectedTab ?? 0)}
                            onMouseLeave={handleMouseLeave}
                            className='service-card-tab'
                            onClick={() => handleOnPress(selectedTab ?? 0, SERVICIOS[selectedTab ?? 0].url)}
                        >
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <img 
                                    src={SERVICIOS[selectedTab ?? 0].image} 
                                    width={'100px'} 
                                    height={'100px'} 
                                    alt={SERVICIOS[0].name} 
                                />
                                <Typography 
                                    variant='h3' 
                                    marginTop={'20px'} 
                                    fontSize={'24px'}
                                    color={'black'}
                                >
                                    {SERVICIOS[selectedTab ?? 0].name}
                                </Typography>
                            </Box>
                        </div>
                        <Box marginTop={'20px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                            <IconButton onClick={() => {
                                if(selectedTab === null || selectedTab === 0){
                                    return;
                                }
                                setSelectedTab(selectedTab - 1)
                            }} sx={{marginRight: '20px'}}>
                                <ArrowBackIcon />
                            </IconButton>
                            <IconButton onClick={() => {
                                if(selectedTab === null || selectedTab === 2){
                                    return;
                                }
                                setSelectedTab(selectedTab + 1)
                            }} sx={{marginLeft: '20px'}}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </Box>
                    </Box>
                ) :
                SERVICIOS.map((x, index) => (
                    <div 
                        key={index}
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={handleMouseLeave}
                        className='service-card-tab'
                        onClick={() => handleOnPress(index, x.url)}
                    >
                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <img src={hoveredIndex === index || selectedTab === index ? x.imageHover : x.image} width={'100px'} height={'100px'} alt={x.name} />
                            <Typography 
                                variant='h3' 
                                marginTop={'20px'} 
                                fontSize={'24px'}
                                color={hoveredIndex === index || selectedTab === index ? '#FCBC45' : 'black'}
                            >
                                {x.name}
                            </Typography>
                        </Box>
                    </div>
                ))
            }
        </Box>

        <Box width={'100%'}  maxWidth={'1200px'}>
            <Typography>
                { selectedTab === null 
                    ? (
                        <Typography textAlign={'center'} mt={2}>
                            Selecciona un servicio para obtener más información
                        </Typography> 
                    ) : (
                        SERVICIOS[selectedTab!].desc.map((x,index) =>(
                            <Typography 
                                paddingLeft={x.includes('•') ? '40px' : undefined} 
                                textAlign={'justify'} 
                                marginBottom={'10px'} 
                                key={index}
                            >
                                {x}
                            </Typography> 
                        ))
                    )}
            </Typography>

            {
                selectedTab !== null && (
                    <SubCategoriesSection service={SERVICIOS[selectedTab!]} />
                )
            }
            
        </Box>

    </Box>
  )
}
