
import { Header } from "../../components/Header/Header"
import { Main } from "../../components/ServiciosContainer/Main/Main"
import { TabsServices } from "../../components/ServiciosContainer/SubMenu/TabsServices"
import { Footer } from "../../components/Footer/Footer"
import { useEffect } from "react"
import ScrollButton from "../../components/ScrollTop/ScrollTop"
import { LinkedinButton } from '../../components/ScrollTop/LinkedinButton';
import { WhatsAppButton } from "../../components/ScrollTop/WhatsAppButton"



export const Servicios = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header 
        goContact={() => {}} 
        goClientes={() => {}} 
        goQuienes={() => {}}
        goHome={() => {}}
      />
      <Main />
      <TabsServices />
      <Footer />

      <ScrollButton />
      <LinkedinButton />
      <WhatsAppButton />
    </div>
  )
}
