
import logo from './logroblanco.png'
import './Footer.css'

export const Footer = () => {
  return (
    <div className='footer-section' style={{ 
      width: '100%', 
      height: '300px', 
      backgroundColor: 'black', 
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <img alt='logo-mr-asoc-blanco' src={logo} width={400} height={'auto'} />
    </div>
  )
}
