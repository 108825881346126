
import { Link } from 'react-router-dom';
import './Header.css';
import { useState } from 'react';

export const SubMenuServices = () => {

    const [ openImpuestos, setOpenImpuestos ] = useState(0);


  return (
    <div className='servicesmenu'>
        
        <ul>
            <li onClick={() => setOpenImpuestos( openImpuestos === 2 ? 0 : 2)} style={{ color: openImpuestos === 2 ? '#FCBC45' : undefined }}>
                Servicios de contabilidad
            </li>
            {
                openImpuestos === 2 && (
                    <>
                        <Link to={'/servicios/contabilidad/1'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Liquidación de sueldos</li>
                        </Link>
                        <Link to={'/servicios/contabilidad/2'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Planeamiento de sueldos</li>
                        </Link>
                    </>
                )
            }
            <li onClick={() => setOpenImpuestos(openImpuestos === 3 ? 0 : 3)} style={{ color: openImpuestos === 3 ? '#FCBC45' : undefined }}>
                Servicios de legales
            </li>
            {
                openImpuestos === 3 && (
                    <>
                        <Link to={'/servicios/legales/1'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Sociedades</li>
                        </Link>
                        <Link to={'/servicios/legales/2'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Civil y comercial</li>
                        </Link>
                        <Link to={'/servicios/legales/3'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Derecho contencioso tributario y de la seguridad social</li>
                        </Link>
                    </>
                )
            }
            <li onClick={() => setOpenImpuestos(openImpuestos === 1 ? 0 : 1)} style={{ color: openImpuestos === 1 ? '#FCBC45' : undefined }}>
                Servicios de impuestos
            </li>
            {
                openImpuestos === 1 && (
                    <>
                        <Link to={'/servicios/impuestos/1'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Impuestos Nacionales y Provinciales</li>
                        </Link>
                        <Link to={'/servicios/impuestos/2'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Planeamiento Impositivo</li>
                        </Link>
                        <Link to={'/servicios/impuestos/3'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Procedimiento Tributario</li>
                        </Link>
                        <Link to={'/servicios/impuestos/4'}>
                            <li className='itemsub' style={{  fontSize: '13px' }}>Servicios Pyme</li>
                        </Link>
                    </>
                )
            }
        </ul>
        
    </div>
  )
}
