
import './Main.css'

export const Main = () => {
  return (
    <div className="container-serv">
      <div className="background-serv"></div>
      <div className="overlay-serv"></div>
        <div className="content-serv">
            <h2>Servicios</h2>
        </div>
    </div> 
  )
}
