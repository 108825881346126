import { SubmitHandler, useForm } from "react-hook-form"
import { useEffect, useState } from 'react'
import emailjs from "@emailjs/browser";
import './Contacto.css'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

type Inputs = {
  name: string
  email: string
  phone?: number
  type: string
  message: string
}

export const Contacto = () => {

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()
  
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setLoading(true);
      await emailjs.send('service_hey6piq', 'template_pjda2t6', {
        name: data.name,
        message: `
          NOMBRE: ${data.name}
          EMAIL: ${data.email}
          CELULAR: ${data.phone}
          MENSAJE: ${data.message}
          MOTIVO DE CONSULTA: ${data.type}
        `,
        reply_to: data.email
      });
      handleClickOpen()
      reset()
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => emailjs.init("Y0pdFulmbCSNhw3If"), [])


  return (
    <div className='contact-section'>
      <div className="services-content">
        <h3>Contacto</h3>
        <div className='service-row'>
          <h2>Dejanos tu consulta</h2>
        </div>

        <div className='column-contact'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input 
              {...register('name', { required: true })}
              style={{ borderColor: errors.name && 'red' }} 
              type='text'
              placeholder='Nombre' 
            />
            <input 
              type='text' 
              placeholder='Mail' 
              {...register('email', { required: true })}
              style={{ borderColor: errors.email && 'red' }} 
            />
            <input 
              type='text' 
              placeholder='Teléfono (opcional)' 
              {...register('phone', { required: false })}
            />
            <input 
              type='text' 
              placeholder='Tipo de consulta' 
              {...register('type', { required: true })}
              style={{ borderColor: errors.type && 'red' }} 
            />
            <textarea 
              cols={30} 
              rows={8}  
              placeholder='Consulta' 
              {...register('message', { required: true })}
              style={{ borderColor: errors.message && 'red' }} 
            />
            {(errors.email || errors.name || errors.message || errors.type) &&
              <p style={{ marginBottom: 20 }}>Completa todos los campos</p>
            }
            <button disabled={loading} className='be-expositor' type="submit">
              {loading ? 'Enviando...' : 'Enviar consulta'}
            </button>
          </form>
          <img src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1696112035/businessman-accountant-working-analyzing-and-calculating-expense-financial-annual-financial-report-e1616122038496_fns5hr.jpg' alt='imagen-contact' />
        </div>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {"Mensaje enviado con exito!"}
          </DialogTitle>
          <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Nos comunicaremos a la brevedad para darle una respuesta. <br />
              Muchisimas gracias
          </DialogContentText>
          </DialogContent>
          <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Cerrar
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  )
}