import { Main } from "./pages/Home/Home";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Servicios } from "./pages/Servicios/Servicios";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/servicios",
    element: <Servicios />,
  },
  {
    path: "/servicios/*",
    element: <Servicios />,
  },
  {
    path: "/*",
    element: <Main />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
