
import { useEffect, useState } from 'react';
import './Header.css';
import logo from './logroblanco.png';
import MenuIcon from '@mui/icons-material/Menu';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { SubMenuServices } from './SubMenuServices';

interface Props {
  goContact : () => void
  goClientes : () => void
  goQuienes: () => void
  goHome: () => void
}

export const Header = ({ goContact, goClientes, goQuienes, goHome }: Props) => {

  const [openSubMenuServices, setOpenSubMenuServices] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [currentIndex, setCurrentIndex] = useState(-1);

  useEffect(() => {
    if(window.location.href.split('/').pop() === ''){
      setCurrentIndex(0)
    }
    if(window.location.href.includes('servicios')){
      setCurrentIndex(2)
    }
  }, [])

  const handleClick = () => {
    setOpenSubMenuServices(false)
    setOpenMenu(!openMenu);
  }

  const isHome = () => window.location.href.split('/').pop() === ''

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
    }}>
      {/* <header className={ scrollPosition < 10 ? "header" : 'header affix'}> */}
      <header className='header'>
        <div className='headeropacity'>
        {
          isHome() ? (
            <img className='logo' src={logo} height={43} alt='logo-rm-asociados' />
          ) : (
            <Link to='/'>
              <img className='logo' src={logo} height={43} alt='logo-rm-asociados' />
            </Link>
          )
        }
        <div>
          <nav>
            <ul>
              <li>
                <Link onClick={goHome} style={{ color: isHome() ? '#FCBC45' : undefined }} to='/'>
                  Inicio
                </Link>
              </li>
              <li>
                <Link onClick={goQuienes} to={'/'}>
                  Quiénes somos
                </Link>
              </li>
              <li onMouseOver={() => setOpenSubMenuServices(true)} >
                <Link style={{ display: 'flex', alignItems: 'center', color: currentIndex === 2 ? '#FCBC45' : undefined }} to="#">
                  Servicios
                  <ExpandMoreIcon />
                </Link>  
              </li>
              <li>
                <Link onClick={goClientes} to="/">
                  Clientes
                </Link>  
              </li>
              <li>
                <Link onClick={goContact} to="/">
                  Contacto
                </Link>
              </li>
              <li>
                <Link onClick={goContact} to="/">
                  <button className='be-expositor'>
                    <AttachMoneyIcon sx={{ marginRight: '10px', marginBottom: '2px' }}  />
                    PEDIR PRESUPUESTO
                  </button>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        
        <div className='menu-icon-container' onClick={handleClick}>
          <MenuIcon className='menu-icon' />
        </div>
        </div>
        {
          openSubMenuServices && (
            <div onMouseLeave={() => setOpenSubMenuServices(false)} className='submenuservices'>
              <SubMenuServices />
            </div>
          )
        }
      </header>
      {
          openMenu && (
            <ul className='lista-mobile'>
              <li style={{ color: isHome() ? '#FCBC45' : undefined }}>
                <Link onClick={() => {
                  goHome()
                  setOpenMenu(false)
                  setOpenSubMenuServices(false)
                }} to='/'>
                  Inicio
                </Link>
              </li>
              <li>
                <Link onClick={() => {
                  goQuienes()
                  setOpenMenu(false)
                  setOpenSubMenuServices(false)
                }} to={'/'}>
                  Quiénes somos
                </Link>
              </li>
              <li onClick={() => setOpenSubMenuServices(!openSubMenuServices)} >
                <Link style={{ display: 'flex', alignItems: 'center',color: currentIndex === 2 ? '#FCBC45' : undefined }} to="/">
                  Servicios
                  <ExpandMoreIcon />
                </Link>  
              </li>
              {
                openSubMenuServices && (
                  <div className='submenuservices'>
                    <SubMenuServices />
                  </div>
                )
              }
              <li>
                <Link onClick={() => {
                  goClientes()
                  setOpenMenu(false)
                  setOpenSubMenuServices(false)
                }} to="/">
                  Clientes
                </Link>  
              </li>
              <li>
                <Link onClick={() => {
                  goContact()
                  setOpenMenu(false)
                  setOpenSubMenuServices(false)
                }} to="/">
                  Contacto
                </Link>
              </li>
              <li>
                <Link onClick={() => {
                  goContact()
                  setOpenMenu(false)
                  setOpenSubMenuServices(false)
                }} to="/">
                  <button className='be-expositor'>
                    <AttachMoneyIcon sx={{ marginRight: '10px', marginBottom: '2px' }}  />
                    PEDIR PRESUPUESTO
                  </button>
                </Link>
              </li>
            </ul>
          )
        }
    </div>
    
  ); 
}
