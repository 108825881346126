
import { useRef } from "react"
import { Clientes } from "../../components/Clientes/Clientes"
import { Contacto } from "../../components/Contacto/Contacto"
import { Footer } from "../../components/Footer/Footer"
import { Header } from "../../components/Header/Header"
import { Home } from "../../components/Home/Home"
import { QuienesSomos } from "../../components/Home/QuienesSomos/QuienesSomos"
import { Services } from "../../components/Home/Services/Services"
import ScrollButton from "../../components/ScrollTop/ScrollTop"
import { WhatsAppButton } from "../../components/ScrollTop/WhatsAppButton"
import { LinkedinButton } from "../../components/ScrollTop/LinkedinButton"


export const Main = () => {

    const homeRef = useRef<HTMLDivElement>(null);
    const quienesSomosRef = useRef<HTMLDivElement>(null);
    const clientesRef = useRef<HTMLDivElement>(null);
    const contactoRef = useRef<HTMLDivElement>(null);

    const scrollToContact = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current!.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <div>
            <Header 
                goContact={() => scrollToContact(contactoRef)} 
                goClientes={() => scrollToContact(clientesRef)}
                goQuienes={() => scrollToContact(quienesSomosRef)} 
                goHome={() => scrollToContact(homeRef)}
            />
            <div ref={homeRef} >
                <Home goQuienes={() => scrollToContact(quienesSomosRef)} />
            </div>
            <div ref={quienesSomosRef}>
                <QuienesSomos />
            </div>
            <Services />
            <div ref={clientesRef}>
                <Clientes />
            </div>
            <div ref={contactoRef}>
                <Contacto />
            </div>
            <Footer />

            <ScrollButton />
            <LinkedinButton />
            <WhatsAppButton />
        </div>
    )
}
