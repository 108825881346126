import './Clientes.css'

export const Clientes = () => {
  return (
    <div className='clientes-container'>
      <div className='clientes-overlay'></div>
      <div className='clientes-content'>
        <h5>Nuestros clientes</h5>
        <h2>Estos son los clientes que confian en nosotros</h2>
        <div className='clientes-card'>
          <div className='clientes-card-container'>
            <div onClick={() => window.open('http://www.wondernew.com.ar')} className='clientes-item'>
              <img style={{ cursor: 'pointer' }} alt='logo-wonder' src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1695786474/Screenshot_2023-09-27_at_00.46.04_hy2qwx.png' width={'100%'} height={'auto'} />
              <span>Wonder New S.R.L.</span>
            </div>
            <div onClick={() => window.open('https://www.igarmados.com.ar/')} className='clientes-item'>
              <img alt='logo-igarmados' style={{paddingTop: '0px', cursor: 'pointer' }} src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1695785849/images_bygdps.png' width={'100%'} height={'100px'} />
              <span>IG Armados S.R.L.</span>
            </div>
            <div onClick={() => window.open('https://www.linkedin.com/company/aperseg-s-a/')} className='clientes-item'>
              <img style={{ cursor: 'pointer' }} alt='logo-aperseg' src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1695786474/Screenshot_2023-09-27_at_00.46.25_w4o1n6.png' width={'100%'} height={'auto'} />
              <span>Aperseg</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
