
import './QuienesSomos.css'
import logo from '../../Header/logonegro.png'


export const QuienesSomos = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="quienesomos">
            <div className='quienesomoscontent'>
              <h3>Bienvenidos a MR & Asoc</h3>
              <h2>Expertos en Asesoramiento Contable para el Éxito Empresarial</h2>
              <p>
              Tenemos como propósito brindar a las empresas e individuos que tengan un negocio en marcha o deseen iniciar una actividad, un asesoramiento integral en materia contable, impositiva, societaria, laboral y previsional, asegurándoles cumplir con toda la normativa vigente y acompañarlos en el desarrollo de sus negocios.<br /><br />
              Como estudio contable impositivo integral, nos orientamos a distintas actividades desarrolladas por empresas unipersonales, sociedades, asociaciones sin fines de lucro, fundaciones, fideicomisos y constructoras. <br /><br />
              Nos mantenemos al tanto de los cambios económicos, políticos, tecnológicos y culturales para evaluar su implicancia sobre las actividades y resultados de nuestros clientes, brindándoles asesoramiento permanente para poder afrontar dificultades y aprovechar las oportunidades que se presenten. 
              </p>
            </div>
            <div className='quienessomosimgcolumn'>
              <img alt='grupo-de-trabajo' id='quienesimg1' width={550} height={330} src='https://res.cloudinary.com/dcyx4jnch/image/upload/v1696187291/business-people-meeting-in-office-top-view-e1616121968371_qajjnk.jpg' />
              <img alt='logo-mr-asoc' width={200} height={'auto'} style={{ marginTop: '20px' }} src={logo} />
            </div>
        </div>
    </div>
  )
}
