
export interface Service {
    name: string;
    desc: string[];
    url: string;
    image: string;
    imageHover: string;
    subcategories: SubCategorie[]
}

export interface SubCategorie {
    name: string;
    detail: string[];
}

export const SERVICIOS: Service[] = [
    {
        name: 'Contabilidad',
        url: 'contabilidad',
        image: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1693426800/cont-negro_pffobr.png',
        imageHover: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1693426800/cont-ama_jsudtr.png',
        desc:  [
            `La tercerización del proceso contable de una empresa o negocio permite a la dirección, 
            socios, gerencia y personal dedicar su tiempo a las competencias esenciales del negocio.
            Al confiar el proceso contable a un estudio especializado, se logra una mayor eficiencia 
            empresarial tanto en el proceso contable en sí como en las actividades principales propias 
            de cada negocio. Esto se debe a la responsabilidad de su correcta ejecución transferida a 
            expertos, al tiempo liberado en la empresa para centrarse en las actividades fundamentales 
            y a los ahorros de costos que resultan de la tercerización.`,
            `Nuestros servicios de tercerización contable incluyen:`,
            `• Registro de las transacciones de la empresa.`,
            `• Generación de estados financieros mensuales.`,
            `• Análisis de la información procesada.`,
            `• Conciliaciones mensuales de cuentas contables.`,
            `• Diseño de planes de cuentas contables`,
            `• Emisión de balances anuales y cumplimiento de las normativas legales actuales.`,
            `Nuestra misión es permitir que las empresas se enfoquen en lo que mejor saben hacer, 
            mientras nosotros nos encargamos del proceso contable con profesionalismo y precisión, 
            liberando recursos valiosos y contribuyendo a una gestión más efectiva y rentable.`,
            `In Company`,
            `• Procesamiento, emisión y envío de la facturación de la empresa`,
            `• Gestión de cobranzas al vencimiento y seguimiento telefónico`,
            `• Proceso de pago a proveedores y emisión de la orden de pago con su respectiva autorización`,
            `• Recepción y control y procesamiento de facturas de proveedores`,
        ],
        subcategories: [
            {
                name: 'Liquidación de sueldos',
                detail: [
                    `La atención personalizada y la confidencialidad en la gestión de información son los 
                    elementos que proporcionan a su empresa la seguridad de la calidad de nuestros servicios. 
                    Además, contamos con experiencia en diversas actividades y sus correspondientes convenios 
                    colectivos de trabajo.`,
                    `Nuestros servicios en esta área abarcan:`,
                    `• Cálculo de sueldos y salarios.`,
                    `• Cálculo de las cargas sociales asociadas.`,
                    `• Generación de recibos de sueldos.`,
                    `• Atención en caso de inspecciones por parte de sindicatos, entidades previsionales y otros temas relacionados con el personal.`,
                    `• Registro de la información según las pautas legales vigentes.`,
                    `• Gestión de trámites ante organismos como ANSES, sindicatos y obras sociales.`,
                    `• Preparación de declaraciones juradas de acuerdo con las normativas legales vigentes.`,
                    `• Creación de registros y datos conforme a los requerimientos contables del cliente.`,
                    `• Implementación de sistemas de Libro de Sueldos digital.`,
                    `• Registro en organismos pertinentes en el área de sueldos: Aseguradora de Riesgos del Trabajo, Seguridad Social, obras sociales, sindicatos.`,
                    `Nuestro compromiso es brindar servicios que reflejen un alto nivel de atención y competencia, 
                    garantizando la tranquilidad de nuestros clientes y permitiéndoles enfocarse en su actividad 
                    principal.`
                ]
            },
            {
                name: 'Planeamiento de sueldos',
                detail: [
                    `• Permanente control de escalas salariales Asesoramiento en la modalidad más conveniente de contratación para cada puesto de trabajo`,
                    `• Asesoramiento en armado de legajos del personal`,
                    `• Asesoramiento integral en legislación laboral`
                ]
            }
        ]
    },
    {
        name: 'Legales',
        desc: [],
        url: 'legales',
        image: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1693280355/legales-negro.png',
        imageHover: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1693280355/legales-ama.png',
        subcategories: [
            {
                name: 'Sociedades',
                detail: [
                    `Nuestro equipo de profesionales altamente especializados en derecho comercial ofrece 
                    una formación integral que abarca una diversidad de asuntos planteados por nuestros 
                    clientes, involucrando múltiples disciplinas legales.`,
                    `Los servicios que proporcionamos en esta área son:`,
                    `• Constitución y asesoramiento integral de empresas locales, entidades civiles y registro de sociedades en Argentina.`,
                    `• Evaluación de la estructura societaria adecuada.`,
                    `• Elaboración de contratos sociales.`,
                    `• Constitución de diversas formas de sociedades comerciales, Uniones Transitorias de Empresas (UTE), asociaciones civiles y fundaciones.`,
                    `• Constitución de aportes en efectivo y en especie.`,
                    `• Asesoramiento en la compra y venta de paquetes accionarios.`,
                    `• Transformación de la forma societaria.`,
                    `• Registro ante las autoridades de supervisión societaria pertinentes.`,
                    `• Preparación de reuniones y asambleas.`,
                    `• Registro de libros oficiales.`,
                    `• Creación de actas de Directorio y de Asamblea, tanto rutinarias como relacionadas con asuntos especiales.`,
                    `• Formalización de contratos de asociación.`,
                    `• Reorganizaciones societarias: fusiones y escisiones.`,
                    `• Elaboración de convenios entre accionistas.`,
                    `Estamos dedicados a proporcionar soluciones jurídicas sólidas y exhaustivas para 
                    respaldar la operación y crecimiento de nuestros clientes en todas las áreas legales 
                    y empresariales.`
                ]
            },
            {
                name: 'Civil y Comercial',
                detail: [
                    `Contratos`,
                
                `Dentro del contexto de nuestro enfoque interdisciplinario, ofrecemos asesoramiento 
                en temas contractuales, participando en la redacción, adaptación, firma y ejecución 
                de diversos tipos de contratos.`,
                
                `En este sentido, brindamos una experiencia dinámica y probada en una amplia variedad 
                de modalidades contractuales, tales como:`,
                
                `• Contrato de leasing.`,
                `• Contratos de préstamo y financiamiento.`,
                `• Contratos de prestación de servicios.`,
                `• Contratos de fideicomisos.`,
                `• Operaciones Inmobiliarias`,
                `• Contratos de locación.`,
                `• Contrato de agencia.`,
                `• Contrato de distribución.`,
                `• Contratos de permuta.`,
                `• Cesión de derechos y de créditos.`,
                `• Contrato de mandato.`,
                `• Contratos agrarios.`,
                
                `Nuestra amplia experiencia nos permite elaborar la documentación necesaria para 
                respaldar de manera sólida las operaciones mencionadas.`,
                
                `Familia y Sucesiones`,
                
                `Sabemos la importancia de las cuestiones familiares, comprendemos y atendemos 
                las necesidades que se van planteando en las distintas etapas de las personas.`,
                
                `En tal sentido, en nuestro departamento de Derecho Civil, ofrecemos servicios como:`,
                
                `• Sucesiones: inicio y finalización de juicios sucesorios, presentaciones en 
                    trámites sucesorios ya iniciados, solicitud de inscripciones, entre otros.`,
                `• Acuerdo de partición sucesoria.`,
                `• Litigios`,
                `• Confección de instrumentos referidos a la planificación patrimonial sucesoria.`,
                `• Elaboración de protocolo familiar.`,
                `• Confección de instrumentos de donación.`,
                `• Acuerdos relacionados con temas matrimoniales y uniones convivenciales.`,
                `• Divorcios.`,
                `• Acciones de filiación.`
                ]
            },
            {
                name: 'Derecho contencioso tributario y de la seguridad social',
                detail: [
                    `Nuestro equipo legal tributario se distingue por su enfoque proactivo e inteligente, 
                que garantiza una defensa efectiva de los derechos del contribuyente, orientada hacia 
                la anticipación y la formulación de estrategias que permiten a nuestros clientes lograr 
                ahorros fiscales significativos.`,
                
                `Nuestros servicios en este ámbito incluyen:`,
                
                `• Diseño y planificación de estrategias para litigios tributarios con el 
                    objetivo de evitar el pago de impuestos nacionales y locales ilegales 
                    o inconstitucionales.`,
                `• Representación en defensas administrativas frente a la AFIP-DGI y a las 
                    direcciones de rentas a nivel municipal y provincial.`,
                `• Asesoramiento y representación en casos de repetición de impuestos, implementando 
                    enfoques inteligentes y efectivos para una resolución rápida.`,
                `• Participación en litigios tributarios complejos, incluyendo tanto el diseño 
                    estratégico de los procesos como el respaldo técnico en cuestiones sustanciales.`,
                `• Intervención y defensa en procedimientos relacionados con la seguridad social.`,
                `• Nos comprometemos a brindar soluciones legales sólidas y efectivas en materia 
                    tributaria, con el objetivo de proteger los intereses de nuestros clientes y 
                    optimizar su situación fiscal.`
                ]
            }
        ]
    },
    {
        name: 'Impuestos',
        url: 'impuestos',
        image: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1693426800/tax-negro_bs7mzz.png',
        imageHover: 'https://res.cloudinary.com/dcyx4jnch/image/upload/v1693426800/tax-ama_mefvxe.png',
        desc: [
            `Nuestra área de especialización abarca impuestos generales, servicios diseñados para 
            pequeñas y medianas empresas (Pymes), así como también atención al sector de expatriados.`,
            `La complejidad y la dinámica que caracterizan al sistema tributario, tanto a nivel nacional 
            como internacional, nos obligan a mantenernos constantemente actualizados y a evaluar 
            distintas alternativas. Nuestro objetivo es ajustarnos a aquellas que reduzcan al mínimo 
            la carga tributaria para nuestros clientes. Esto permite que los líderes empresariales puedan 
            centrarse en sus operaciones en un entorno cada vez más competitivo, tal como lo exigen las 
            circunstancias actuales.`
        ],
        subcategories: [
            {
                name: 'Impuestos Nacionales y Provinciales',
                detail: [
                    `Nuestra gama de servicios se ofrece con la participación directa de todo nuestro staff, 
                y se centra en la planificación y supervisión estratégica de la carga tributaria 
                asociada a las operaciones económicas de cada empresa. De esta manera, buscamos 
                garantizar una adecuada estructura fiscal para todas las operaciones.`,
                
                `Combinamos la sólida experiencia técnica de nuestros profesionales con plataformas 
                tecnológicas que nos permiten crear entornos flexibles y respuestas rápidas. Esto nos 
                capacita para desempeñar un papel fundamental como asesores estratégicos para nuestros 
                clientes y sus negocios.`,
                
                `• Elaboración y revisión de declaraciones juradas de impuestos (liquidaciones mensuales y 
                    anuales de tributos nacionales, provinciales y municipales: IVA, Ganancias, 
                    Bienes Personales, Ingresos Brutos para ámbito local y Convenio Multilateral).`,
                `• Preparación y presentación de moratorias y planes de facilidades.`,
                `• Expatriados: Estrategia integral en relación a Convenios de doble imposición y 
                    Tratados bilaterales de Seguridad Social (Régimen Jubilatorio). Asesoramiento 
                    sobre tratamientos especiales en la liquidación de impuesto a las ganancias, bienes 
                    personales y autónomos.`,
                `• Creación y revisión de conciliaciones entre saldos impositivos y contables.`,
                `• Verificación del cumplimiento de las normativas de facturación y registro.`,
                `• Supervisión de diversos regímenes de retención de impuestos.`,
                `• Participación en procesos de fiscalización ante entidades de control (nacionales, 
                    provinciales y municipales).`,
                `• Consultoría fiscal y previsional.`,
                `• Recuperación de saldos a favor impositivos.`,
                `• Liquidación de impuestos anuales para individuos. Planificación fiscal para patrimonios 
                    y empresas familiares.`,
                `• Asistencia en la creación de empresas innovadoras, start-ups y su impacto fiscal 
                    (Fintech, billeteras virtuales y criptomonedas, robótica, inteligencia artificial, 
                    capital humano y salud).`,
                `• Para pequeños contribuyentes nos especializamos en Asesoramiento y atención a 
                    Monotributistas: Inscripción Recategorización, modificación de Datos, Bajas.`
                ]
            },
            {
                name: 'Planeamiento Impositivo',
                detail: [
                    `
                La planificación impositiva se vuelve esencial tanto en la fase inicial de creación de un 
                negocio como en momentos de cambio, como la implementación de nuevas regulaciones, 
                modificaciones en la operación o una reestructuración.`,
                
                `Nuestros servicios en esta área incluyen:`,
                
                `• Estrategias de planificación impositiva en la evaluación de nuevos proyectos y 
                    la adaptación de procesos en empresas ya establecidas.`,
                 `• Asesoramiento a asociaciones empresariales en asuntos fiscales específicos de 
                    su sector, así como guía en la elaboración de propuestas normativas estratégicas 
                    para la industria.`,
                
                `Reconocemos la importancia de una planificación tributaria efectiva en la toma de decisiones empresariales y trabajamos para proporcionar a nuestros clientes las herramientas necesarias para abordar estos aspectos con éxito.`
                ]
            },
            {
                name: 'Procedimiento Tributario',
                detail: [
                    `
                La creciente complejidad y la incertidumbre inherente a la legislación tributaria 
                han sido acompañadas por un incremento constante en la cantidad de disputas. 
                La presentación precisa y puntual de defensas, recursos administrativos o legales, 
                y la presentación de pruebas y argumentos en respuesta a posibles reclamaciones, 
                son elementos esenciales para gestionar de manera exitosa este tipo de conflictos.`,
                
                `Nuestra gama de servicios en este ámbito abarca:`,
                
                `• Identificación y prevención de conductas que puedan generar reclamaciones fiscales.`,
                `• Asesoramiento y representación en respuesta a posibles ajustes fiscales por 
                    parte de las autoridades tributarias.`,
                `• Soporte técnico en la elaboración de argumentos de defensa, tanto en instancias 
                    administrativas como judiciales, con el respaldo de abogados especializados en 
                    cuestiones fiscales y legales.`,
                ]
            },
            {
                name: 'Servicios Pyme',
                detail: [
                    `
                Podemos ofrecer soluciones mediante propuestas de mejores prácticas para 
                establecer administraciones eficientes y flexibles. Nuestra experiencia 
                también nos permite brindar asistencia a empresas multinacionales en todas las 
                fases de su desarrollo, actuando como socios independientes en la generación de 
                información confiable y puntual.`,
                
                `Nuestra gama de servicios abarca:`,
                
                `• Asesoramiento integral en temas contables y elaboración de informes.`,
                `• Generación de la información contable necesaria para trámites relacionados con la estructura 
                    societaria.`,
                `Estamos comprometidos a proporcionar servicios que respondan a tus necesidades específicas, 
                con el objetivo de optimizar la gestión y ofrecer información sólida y útil para la toma de 
                decisiones.`
                ]
            }
        ]
    }
]